
import { Component } from 'vue-property-decorator';
import Editable from '@/mixins/Editable';
import { mixins } from 'vue-class-component';
import VWrapper from '@/components/shared/VWrapper.vue';
import EditOptionLoader from '../../components/option/EditOptionLoader.vue';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import OptionForm from '@/components/option/OptionForm.vue';
import OptionArticleTable from '@/components/option/article/OptionArticleTable.vue';
import OptionArticleForm from '@/components/option/article/OptionArticleForm.vue';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import StackedForm from '@/mixins/StackedForm';
import { EditOption as EditOptionMixin } from '@/mixins/EditOption';
import Notification from '@/mixins/Notification';
import { namespace } from 'vuex-class';
import { Tag } from '@/interfaces/models/Tag';
import Filter from '@/interfaces/api/Filter';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import FormatPriceInObject from '@/mixins/FormatPriceInObject';
import { VList, VNavigationDrawer } from 'vuetify/lib';
import AvailableHourForm from './form/AvailableHourForm.vue';
import { Constrain } from '@/interfaces/models/Constrain';

const tag = namespace('tag');
const printGroups = namespace('printGroups');
const foodcard = namespace('foodcard');
const constrain = namespace('constrain');

@Component({
  components: {
    OptionArticleForm,
    OptionArticleTable,
    OptionForm,
    EditOptionLoader,
    VWrapper,
    VNavigationDrawer,
    VList,
    AvailableHourForm,
  },
})
export default class EditOptionDialog extends mixins(
  Editable,
  StackedForm,
  Notification,
  EditOptionMixin,
  FormatPriceInObject,
) {
  @tag.Action('setFilter') public setFilter!: (filter: Filter) => void;
  @tag.Action('fetch') public getTags!: any;
  @tag.State('items') public tags!: Tag[];

  @printGroups.State('items') public printGroups!: PrintGroups[];
  @constrain.State('items') public constrains!: Constrain[];
  @foodcard.Action('setOptionArticleFilter') public setOptionArticleFilter!: any;

  public showModal: boolean = false;

  public tab = 0;
  public $refs!: {
    form: InstanceType<typeof OptionForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
    optionArticleForm: InstanceType<typeof OptionArticleForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    availableHours: InstanceType<typeof AvailableHourForm> & { getData: () => any };
  };

  public async handleAddOptionArticle() {
    this.optionModalVisible = true;
    this.$nextTick(() => {
      this.activeOptionArticle = null;
      this.$refs.optionArticleForm.reset();
    });
  }

  public async handleCancelOptionArticle() {
    this.optionModalVisible = false;
    this.activeOptionArticle = null;
    this.$nextTick(() => {
      this.$refs.optionArticleForm.reset();
    });
  }

  public async mounted(): Promise<void> {
    this.setOptionArticleFilter({});
    this.setActive(this.option);
    if (this.venue && this.venue._id) {
      this.setFilter({ venue: this.venue._id });
      this.$startLoading('tags');
      await this.getTags();
      this.$stopLoading('tags');
    }
  }

  public updateProp(data: { original: OptionArticle; field: string; value: string }) {
    const update: any = {};
    update[data.field] = data.value;
    this.updateOptionArticle({ id: data.original._id, group: this.active._id, ...update });
  }

  public async onSearch(queries: any) {
    this.setOptionArticleFilter({ ...this.optionArticleFilter, ...queries });
  }

  public async onCategoryChange(category: string) {
    this.setOptionArticleFilter({ category, ...this.optionArticleFilter });
  }

  public async updateExternalOrderProvider(data: { original: OptionArticle; update: any }) {
    this.$startLoading('option.article');
    await this.updateOptionArticle({
      id: data.original._id,
      group: this.active._id,
      externalOrderProviderSettings: data.update,
    });
    this.$stopLoading('option.article');
  }

  public saveOptionArticle() {
    this.$refs.optionArticleForm.validate().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        let reset: boolean = false;
        const data: Partial<OptionArticle> = { group: this.active._id, ...this.$refs.optionArticleForm.getData() };
        // TODO - may mutate unexpected fields
        const arr = ['price', 'displayPrice', 'standard', 'inside', 'takeAway', 'delivery'];
        arr.map((type: any) => {
          // mutate merged data
          const res = this.deepSearchItems(data, type, (k: any, v: any) => {
            if (v && v.includes) {
              return v.includes(',');
            }
          });
          res.map((obj: any) => {
            obj[type] = obj[type].replaceAll(',', '.');
          });
        });
        this.$startLoading('updateOption');
        if (this.activeOptionArticle) {
          await this.updateOptionArticle({ ...data, id: this.activeOptionArticle._id });
        } else {
          reset = true;
          this.activeOptionArticle = await this.storeOptionArticle(data);
        }

        if (data.assets && data.assets instanceof File) {
          const formData: FormData = new FormData();
          formData.append('asset', data.assets);
          await this.uploadOptionArticleImage({
            group: this.active._id,
            id: this.activeOptionArticle!._id,
            image: formData,
          });
        }

        if (data.icon && data.icon instanceof File) {
          const formData: FormData = new FormData();
          formData.append('asset', data.icon);
          await this.uploadOptionArticleIcon({
            group: this.active._id,
            id: this.activeOptionArticle!._id,
            image: formData,
          });
        }

        if (reset) {
          this.$refs.optionArticleForm.reset();
        }

        this.$stopLoading('updateOption');
        this.activeOptionArticle = null;
        this.optionModalVisible = false;
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        const data: Partial<OptionGroup> = {
          venue: this.venue._id,
          ...this.$refs.form.getData(),
          ...this.$refs.availableHours.getData(),
        };
        this.$startLoading('option');
        if (this.editing) {
          await this.update({ id: this.active._id, ...data });
        } else {
          await this.store(data);
        }
        if (!this.active) {
          this.setShowModal(false);
          return;
        }
        let formData: FormData;

        if (data.backgroundImage && data.backgroundImage instanceof File) {
          formData = new FormData();
          formData.append('asset', data.backgroundImage as Blob);
          await this.uploadImage({ id: this.active._id, image: formData });
        }
        this.$stopLoading('option');
        this.setShowModal(false);
      }
    });
  }

  public setShowModal(showModal: boolean = true) {
    this.showModal = showModal;
    if (!showModal) {
      // Reset active option when dismissing the modal
      this.resetOption();
    }
  }
}
